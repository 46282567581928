<template>
  <div
    v-if="products && products.length"
    class="super-deals"
    :style="superDealsStyle"
    :data-background-image="dataBackgroundImage"
  >
    <!-- header -->
    <div
      v-tap="getAnalysisData('2-22-2', { item: { ...cate, item_loc: 1, useBffApi: true }, index }, true)"
      v-expose="getAnalysisData('2-22-1', { item: { ...cate, item_loc: 1, useBffApi: true }, index }, true)"
      class="super-deals__header"
      @click="toSuperSalePage()"
    >
      <span class="super-deals__title_l">
        <!-- img 标签 -->
        <BaseImg
          v-if="titleImage?.src"
          fit="cover"
          :style="[titleImageSize]"
          :img-src="titleImage?.src"
          :placeholder="{
            width: getTitleImgWidth(),
            height: 16
          }"
          :ratio="titleImage?.ratio || 1"
          :position="GB_cssRight ? '100% 50%' : '0% 50%'"
          class="not-fsp-element"
          :img-design-width="getTitleImgWidth() * 3"
          :first-screen="propData?.isFirstPage"
        />
        <span
          v-else
          class="super-deals__text"
          :style="{ color: titleColor || '#000000' }"
        >
          <template v-if="homeDiscountNew == 'show'">
            <BaseImg
              v-if="titleColor == '#000000' || !titleColor"
              fit="contain"
              :img-src="iconStyle"
              :placeholder="{
                width: 11,
                height: 16
              }"
              :style="{
                width: '.2933rem',
                height: '.4267rem'
              }"
              :ratio="0.6875"
              class="not-fsp-element"
              :first-screen="propData.firstScreen"
            />
            <Icon
              v-else
              name="sui_icon_deals_titleip_16px"
              size="16px"
              color="#FFE895"
              :is-rotate="GB_cssRight"
            />
          </template>
          <span>
            {{ metaData?.firstTitle }}
          </span>
        </span>
      </span>
      <span
        class="super-deals__title_r"
        :style="{ color: subTitleColor || '#5E3333' }"
      >
        <span class="super-deals__title">
          {{ metaData.secondTitle || 'View More' }}
        </span>
        <Icon
          name="sui_icon_more_right_12px"
          :is-rotate="GB_cssRight"
        />
      </span>
    </div>
    <!-- 商品区域 -->
    <VirtualSwiper
      v-if="products.length > 0"
      ref="singleContainerSwiperRef"
      class="super-deals__swiper-container"
      :options="options"
      :source-data="products"
      :initVirtualNum="5"
    >
      <template #default="{ virtualData }">
        <swiper-slide
          v-for="(item, itemIndex) in virtualData"
          :key="`${item.goods_id}_${itemIndex}`"
          class="super-deals__item"
          :style="sliderItemStyle"
          :class="itemClass(item)"
        >
          <ProductItem
            ref="goodsItem"
            v-tap="getTapAnalysis(item, itemIndex)"
            v-expose="getExposeAnalysis(item, itemIndex)"
            :item="item"
            :index="index"
            :config="itemConfig"
            :isFirstPage="propData?.isFirstPage"
            :language="itemLanguage"
            :constant-data="constantData"
            :da-rid="itemIndex + 1 == products.length && `superSale_${index}`"
            :context="context"
            :show-view-more="itemIndex === products.length - 1"
            :view-more-text="viewMoreText"
            :css-right="GB_cssRight"
            :lcp="propData?.isFirstPage && itemIndex < 5"
            :comp-src="'super-deals'"
            :comp-img-design-width="192"
            :crop-rate="isHomeR56 ? '5-6' : '3-4'"
            :price-font-size="metaData?.priceFontSize || {}"
            :suggested-sale-type="suggestedSaleType"
            @clickItem="toSuperSalePage(item)"
            @viewMore="toSuperSalePage(item)"
          />
        </swiper-slide>
      </template>
    </VirtualSwiper>
  </div>
</template>
<script>
import mixin from 'public/src/pages/components/ccc/components/mixins/mixin.js'
import useProductItemMixin from 'public/src/pages/components/ccc/components/mixins/use-product-item.js'
import BaseImg from 'public/src/pages/components/ccc/base/BaseImg.vue'
import { commonProps } from 'public/src/pages/components/ccc/common/common-props.js'
import ProductItem from 'public/src/pages/components/ccc/base/ProductItemSimpleCccBff.vue'
import VirtualSwiper from 'public/src/pages/components/ccc/base/swiper/VirtualSwiper.vue'
import { Icon } from '@shein-aidc/icon-vue2'
import { transfromAndCutImg } from 'public/src/services/resource'
import { register } from 'swiper/element'
import { badgeMap, beltMap, borderMap } from './constants.js'
import { handleColor } from 'public/src/pages/components/ccc/common/utils'
const defaultBackgroundImageUrl = 'https://img.ltwebstatic.com/images3_ccc/2024/05/15/57/1715758373e1c9819d9004e71c901c186aff1890dc.png'

if (typeof window !== 'undefined') {
  register()
}

export default {
  name: 'SuperDealsBff',
  components: {
    BaseImg,
    Icon,
    VirtualSwiper,
    ProductItem,
  },
  mixins: [mixin, useProductItemMixin],
  props: commonProps,
  data() {
    const compLanguage = this.language?.superSales || {}
    const viewMoreText = this.language?.productList?.SHEIN_KEY_PWA_21825 || 'View more'
    const { GB_cssRight, PUBLIC_CDN, lang, SiteUID } = this.context || {}
    let {
      HomeDiscount: homeDiscountNew,
      BGPrice,
      HomePictureProcess,
      FSCategory,
      SuperDealsPerformanceOptimization,
      BGbelttype,
      BGDiscount
    } = this.context?.abtInfoObj || {}
    if (SiteUID === 'pwde' && homeDiscountNew === 'compliance') {
      // de站点合规时以noshow处理
      homeDiscountNew = 'noshow'
    }
    const { metaData: metaDataBff = {} } = this.propData?.props || {}
    const categoryType = metaDataBff.categoryType
    const frontCateIds = metaDataBff.frontCateIds
    const bgImage = metaDataBff.bgImage
    const titleImage = metaDataBff.titleImage
    const titleColor = handleColor(metaDataBff.titleColor)
    const subTitleColor = handleColor(metaDataBff.subTitleColor)
    if (titleImage?.src && !titleImage?.ratio) {
      const { width = 0, height = 0 } = titleImage || {}
      titleImage.ratio = width / height
    }
    const showBelt = Number(metaDataBff.showBelt)
    const showFlashBorder = Number(metaDataBff.showFlashBorder)
    let flashProducts = metaDataBff.flashProducts || []
    if (flashProducts && flashProducts?.length) {
      flashProducts.forEach(element => {
        if (showFlashBorder) {
          element.homeBelt = {
            // 腰带
            text: compLanguage.SHEIN_KEY_PWA_14949, // 文本
            textColor: '#222', // 文本颜色
            textSize: '10', // 文本大小
            textWeight: '700',
            textHeight: '70%',
            bgImage: {
              // 背景图
              // 闪购腰带
              src: beltMap.flashSale,
              width: 248,
              height: 60,
              ratio: 4.1333
            }
          }
          element.homeBorder = {
            // 闪购边框
            image: {
              // 背景图
              src: borderMap.flashSale,
              width: 248,
              height: 330,
              ratio: 0.72
            }
          }
        }
        if (
          homeDiscountNew !== 'noshow' &&
          element.unit_discount &&
          Number(element.unit_discount) != 0
        ) {
          const badge = {
            // 角标
            text: '', // 文本
            textColor: '#222', // 文本颜色
            textSize: '9', // 文本大小
            textNoRotate: true, // 文本固定样式不翻转，通过文字翻转
            icon: {
              // 图标
              src: badgeMap.flashSaleIcon,
              size: '0.27rem', // icon大小
              width: 30,
              height: 30,
              ratio: 1,
              needRotate: true
            },
            bgImage: {
              // 背景图
              // 闪购角标
              src: badgeMap.flashSale,
              width: 248,
              height: 60,
              ratio: 4.1333
            }
          }
          const unitDiscountString = element.unit_discount
          let badgeText = `${lang === 'il' || lang === 'he' ? '' : '-'}${unitDiscountString}%` // il语言折扣无负号
          if (GB_cssRight && badgeText) {
            // 镜像站点百分比字符翻转
            badgeText = badgeText.replace(/(-?)(\d+(\.\d+)?)(%)/, '$4$2$1')
          }
          badge.text = badgeText
          if (badge.text) {
            if (!badge.icon?.src) {
              // 无图标，精减字段
              delete badge.icon
            }
            element.homeBadge = badge
          }
        }
        element.type = 'flashSale'
      })
    }
    let superDealsProducts = metaDataBff.products || []
    if (superDealsProducts && superDealsProducts?.length) {
      superDealsProducts.forEach(element => {
        if (homeDiscountNew !== 'noshow' && showBelt && element.superDealsBeltText) {
          element.homeBelt = {
            // 腰带
            text: element.superDealsBeltText, // 文本
            textColor: '#fff', // 文本颜色
            textSize: '10', // 文本大小
            bgImage: {
              // 背景图
              // 闪购腰带
              src: beltMap.superDeals,
              width: 248,
              height: 39,
              ratio: 6.4615,
              needRotate: true
            },
            icon: {
              // 图标
              src: beltMap.superDealsIcon,
              width: 30,
              height: 30,
              ratio: 1,
              needRotate: true
            }
          }
          if (BGbelttype === 'newbelt' || BGbelttype === 'save') {
            element.homeBelt.bgImage.src = beltMap.superDealsNew
            element.homeBelt.bgImage.ratio = 6.3589
          }
        }
        if (
          homeDiscountNew !== 'noshow' &&
          BGDiscount === 'discount' &&
          element.unit_discount &&
          Number(element.unit_discount) != 0
        ) {
          const badge = {
            // 角标
            text: '', // 文本
            textColor: '#fff', // 文本颜色
            textSize: '9', // 文本大小
            textNoRotate: true, // 文本固定样式不翻转，通过文字翻转
            bgImage: {
              // 背景图
              src: badgeMap.superDeals,
              width: 102,
              height: 36,
              ratio: 2.8333
            }
          }
          const unitDiscountString = element.unit_discount
          let badgeText = `${lang === 'il' ? '' : '-'}${unitDiscountString}%` // il语言折扣无负号
          if (GB_cssRight && badgeText) {
            // 镜像站点百分比字符翻转
            badgeText = badgeText.replace(/(-?)(\d+(\.\d+)?)(%)/, '$4$2$1')
          }
          badge.text = badgeText
          if (badge.text) {
            if (!badge.icon?.src) {
              // 无图标，精减字段
              delete badge.icon
            }
            element.homeBadge = badge
          }
        }
        element.type = 'superSale'
      })
    }
    const products = [...flashProducts, ...superDealsProducts]
    let suggestedSaleType = ''
    const isDeStyle = metaDataBff.isDeStyle ? Number(metaDataBff.isDeStyle) : 0
    const nextLine = metaDataBff.nextLine ? Number(metaDataBff.nextLine) : 0
    if (isDeStyle) {
      suggestedSaleType = 'special_de'
    } else if (SiteUID === 'mpl') {
      suggestedSaleType = 'special'
    } else {
      suggestedSaleType = 'normal'
    }
    const extendData =
      this.sceneData?.extendData || this.context?.content?.extendParse || {}
    return {
      viewMoreText,
      compLanguage,
      titleImage,
      titleColor,
      subTitleColor,
      bgImage,
      categoryType,
      frontCateIds,
      products,
      GB_cssRight,
      PUBLIC_CDN,
      homeDiscountNew,
      showStrengthenPrice: BGPrice === 'newprice13', // 不贵组件加强价格
      isHomeR56: HomePictureProcess === 'Home_picture',
      useFrontCateId: FSCategory === 'B',
      isEarlyRequest0903: SuperDealsPerformanceOptimization === 'early_tmg_request_0903', // 是否是0903之前的请求
      isDeStyle,
      nextLine, // 合规价是否和售价同行
      suggestedSaleType,
      // 初始配置
      options: {
        direction: 'horizontal',
        slidesPerView: 4.2,
        spaceBetween: 4,
        virtualEnable: true, // 启用虚拟slides
        observeResize: true, // 监听resize，自动更新
      },
      itemConfig: {
        showCamelPrice: true, // 驼峰式价格
        disableMainimgJump: true,
        showNewDiscountBlet: false, // 是否展示新的闪购腰带
        hideRetailPrice: true, // 隐藏零售价（划线价）- 全端去除划线价
        hideS3VipPrice: true, // 隐藏s3会员价格
        noNeedAnyEstimatedPriceTag: true, // 不需要任何到手价标签
        hideIconBeforePrice: homeDiscountNew === 'noshow', // 隐藏价格前面的icon
        hideServiceLabels: homeDiscountNew === 'noshow', // 服务标签
        camelPriceResizeFontsize: true, // 驼峰价格动态字号
        showSuggestedSalePrice: homeDiscountNew === 'compliance' // 是否展示合规价
      },
      extendData,
      defaultCardStyle: {
        margin: '0 .2133rem .2667rem'
      }
    }
  },
  computed: {
    // 标题图大小
    titleImageSize() {
      return {
        width: this.getTitleImgWidth() / 37.5 + 'rem',
        height: '.4267rem',
      }
    },
    cate() {
      return {
        markMap: this.propData?.props?.markMap || {},
        hrefType: 'thriftyFind',
        categoryIds: this.categoryIds || '',
        hrefTarget: this.nodeId || ''
      }
    },
    nodeId() {
      let nodeId = ''
      switch (Number(this.categoryType)) {
        case 1:
          // 选中“默认”或“跟随频道”时，从频道获取前台类目；
          nodeId = this.context?.curChannelInfo?.tspNodeIds || ''
          break
        case 3:
          // 选中“前台类目”时，优先使用组件配置的前台类目。前台类目为空时，从频道获取前台类目
          nodeId = this.frontCateIds || this.context?.curChannelInfo?.tspNodeIds || ''
          break
        // 选中“全品类”时，前台类目传空，获取全部商品
      }
      return nodeId
    },
    categoryIds() {
      if (this.useFrontCateId) {
        return this.context?.curChannelInfo?.tspNodeIds || ''
      }
      return this.context?.curChannelInfo?.realCateIds
    },
    dataBackgroundImage() {
      return this.cutImg(this.bgImage?.src || defaultBackgroundImageUrl)
    },
    superDealsStyle() {
      const style = this.isCardStyle ? this.cardStyle : this.defaultCardStyle
      return {
        ...style,
        background: this.initialBannerBg(this.bgImage?.src || defaultBackgroundImageUrl)
      }
    },
    iconStyle() {
      return this.GB_cssRight
        ? `${this.PUBLIC_CDN}/pwa_dist/images/super-sale/frame_icon-3xr-4aea900719.png`
        : `${this.PUBLIC_CDN}/pwa_dist/images/super-sale/frame_icon3x-cdcc97079d.png`
    },
    // 卡片化字段为false，或者边距、圆角 这2项都获取失败或为空时，代表非卡片化样式，展示原有样式
    isCardStyle() {
      const {
        isCardShow = false,
        margin: marginList = [],
        cardRadius = 0
      } = this.extendData || {}
      if (!isCardShow) return false
      if (isCardShow || cardRadius > 0) return true
      return Array.isArray(marginList) && marginList.some(item => item > 0)
    },
    cardStyle() {
      const { margin = [], cardRadius = 0 } = this.extendData || {}
      const marginList =
        Array.isArray(margin) && margin.length === 4
          ? margin.slice()
          : Array(4).fill(0)
      const marginStyle = marginList.map(item => `${Number(item)}px`).join(' ')
      const styleResult = {
        margin: marginStyle,
        overflow: 'hidden'
      }
      styleResult.borderRadius = `${Number(cardRadius)}px`
      return styleResult
    },
    sliderItemStyle() {
      const { slidesPerView, spaceBetween = 8 } = this.options
      const style = {}
      // auto 时不计算
      if (slidesPerView !== 'auto') {
        // 每个slide的宽度 = (容器宽度 - (每屏显示个数 - 1后再乘以间距，得到总跨度)) / 每屏显示个数
        style.width = `calc((100% - ${(Number(slidesPerView) - 1) * (Number(spaceBetween) ?? 8)}px) / ${Number(slidesPerView)})`
        const gap = (Number(spaceBetween) ?? 8) + 'px'
        const { GB_cssRight = false } =
          typeof gbCommonInfo === 'undefined' ? {} : gbCommonInfo
        style.marginLeft = GB_cssRight ? gap : 'initial'
        style.marginRight = GB_cssRight ? 'initial' : gap
      }
      return style
    }
  },
  methods: {
    cutImg(imgUrl, forceTransform = false) {
      const {
        deviceData = '',
        isSupportWeb = '',
        sceneMap = {},
        isSupprotCut = ''
      } = this.context?.RESOURCE_SDK || {}
      const cutData = {
        deviceData,
        isSupportWebp: forceTransform ? true : Boolean(isSupportWeb),
        isSupprotCut: forceTransform ? true : Boolean(isSupprotCut),
        imgUrl,
        designWidth: 950,
        sceneMap,
        exp: 'unCut=0#max=950'
      }
      return transfromAndCutImg(cutData)
    },
    getTitleImgWidth() {
      return 16 * Number(this.titleImage?.ratio) + 1 // 适当增加1px的宽度，避免图片显示不全
    },
    initialBannerBg(img) {
      return `url(${this.cutImg(img)}) no-repeat`
    },
    itemClass(colItem) {
      if (this.homeDiscountNew === 'show') {
        return {
          'strengthen-price': this.showStrengthenPrice
        }
      }
      if (this.homeDiscountNew === 'noshow') {
        return {
          'product-item__discount_color-hide': true
        }
      }
      return {
        'super-deals__item_suggested': true,
        'super-deals__item_suggested-label': colItem?.suggestedSalePriceInfo?.description,
        'super-deals__item_suggested-sameline': !this.nextLine, // de合规价固定不受nextLine影响
        // 'super-deals__item_suggested-de': this.isDeStyle // de合规价样式覆盖，de不贵组件无合规价，de站点合规时以noshow处理
      }
    },
    // 跳转
    toSuperSalePage({ type, goods_id } = {}) {
      // cccLink from mixin
      let url = this.cccLink.getFullLink({
        item: {
          ...this.metaData,
          hrefType: 'thriftyFind',
          categoryIds: this.categoryIds,
          item_loc: 1,
          hrefTarget: this.nodeId
        },
        cateLinks: this.cateLinks,
        compIndex: this.index,
        index: 0,
        isBff: true
      })
      // 列表需要的参数
      const listView = window?.localStorage.getItem('super_deals_product_list_view') || ''
      let flashIds, superIds
      if (type == 'flashSale') {
        // 组装数据
        flashIds = [
          goods_id,
          ...this.products
            .filter(i => i.type == 'flashSale')
            ?.map(i => i.goods_id)
            .filter(i => i !== goods_id)
        ].join(',')
        superIds = this.products
          .filter(i => i.type != 'flashSale')
          ?.map(i => i.goods_id)
          .join(',')
      } else {
        // 组装数据
        superIds = [
          goods_id,
          ...this.products
            .filter(i => i.type != 'flashSale')
            ?.map(i => i.goods_id)
            .filter(i => i !== goods_id)
        ].join(',')
        flashIds = this.products
          .filter(i => i.type == 'flashSale')
          ?.map(i => i.goods_id)
          .join(',')
      }
      let realCateIds = ''
      switch (Number(this.metaData.categoryType)) {
        case 1:
          // 选中“默认”或“跟随频道”时，从频道获取前台类目；
          realCateIds = this.context?.curChannelInfo?.realCateIds || ''
          break
        case 3:
          // 选中“前台类目”时，优先使用组件配置的前台类目。前台类目为空时，从频道获取前台类目
          realCateIds = this.metaData.categoryIds || this.context?.curChannelInfo?.realCateIds || ''
          break
        // 选中“全品类”时，前台类目传空，获取全部商品
      }
      const adpStr = `adp=${superIds}&flash_adp=${flashIds}&cat_id=${realCateIds}&node_id=${this.nodeId}&pagefrom=page_home&list_view=${listView}`
      url = url.indexOf('?') > -1 ? `${url}&${adpStr}` : `${url}?${adpStr}`
      url = `${url}&eventTimestamp=${new Date().getTime()}`
      if (this.isEarlyRequest0903) {
        url = `${url}&isEarlyRequest0903=1`
      }
      if (this.metaData?.mallCodes) {
        url = `${url}&mall_code=${this.metaData?.mallCodes}`
      }
      this.jumpLink({ url, hrefType: 'super-deals' })
    },
    getTapAnalysis(item, i) {
      return this.getProductAnalysis('2-22-4', {
        index: i,
        item,
        cate: { ...this.cate, item_loc: 0 },
        isJumpToList: true,
        useBffApi: true
      })
    },
    getExposeAnalysis(item, i) {
      return this.getProductAnalysis('2-22-3', {
        type: 'expose',
        index: i,
        item,
        cate: { ...this.cate, item_loc: 0 },
        isJumpToList: true,
        useBffApi: true
      })
    }
  },
}
</script>
<style lang="less" scoped>
.super-deals {
  position: relative;
  margin: 0 0.2133rem;
  position: relative;
  overflow: hidden;
  border-radius: 0.0533rem;
  &__header {
    display: flex;
    justify-content: space-between;
    margin: 0.2133rem 0.16rem;
  }
  &__title_r {
    display: flex;
    align-items: center;
    text-align: right;
    font-size: 0.2933rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  &__swiper-container {
    display: flex;
    flex-wrap: nowrap;
    padding-left: 0.1067rem;
    .product-item__discount_color-hide {
      /deep/ .normal-price__sale-price_promo,
      /deep/ .normal-price__sale-price,
      /deep/ .prices-info__sale-price {
        color: @sui_color_main;
      }
    }
  }
  &__item {
    flex-shrink: 0;
    height: auto;
    z-index: 1;
    // 合规价样式覆盖
    &_suggested {
      /deep/ .product-bff {
        .product-card__prices {
          max-height: unset;
        }
        .product-card__prices-info, 
        .prices-info {
          width: 100%;
        }
        .bff-price-container {
          align-items: baseline;
          position: relative;
          .bff-price-container__top {
            .product-card__camel-case-price {
              height: unset;
              line-height: normal;
            }
          }
          .bff-price-container__top, 
          .prices-info__sale-price {
            height: unset;
            line-height: normal;
          }
          .prices-info__suggested-sale-price {
            flex: unset;
          }
          .product-card__price-suggested {
            font-size: 0.24rem;
            margin-left: 2px;
          }
          .suggested-label {
            position: absolute;
            bottom: 2px;
            left: 0;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
    &_suggested.super-deals__item_suggested-label /deep/ .bff-price-container  {
      padding-bottom: 12px;
    }
    // 合规加展示价格同行
    &_suggested-sameline /deep/ .product-bff .bff-price-container {
      flex-direction: row;
    }
    // de合规价样式覆盖
    &_suggested-de {
      /deep/ .product-bff {
        .bff-price-container {
          .bff-price-container__top {
            flex-wrap: nowrap;
            width: 100%;
            .prices-info__sale-price {
              max-width: calc(100% - 0.48rem); // 划线价如果展示不下时要末尾「…」，不能隐藏
            }
            .product-card__price-suggested { 
              display: block;
              margin-left: 2px;
            }
          }
          .product-card__price-suggested {
            display: flex;
            align-items: center;
            max-width: 100%;
            margin-left: 0;
            .suggested-label {
              position: unset;
            }
          }
        }
      }
      /deep/ .product-bff.super-deals__item_suggested-label .bff-price-container {
        padding-bottom: 0px;
      }
    }
  }
  .strengthen-price .simple-row-item {
    /deep/ .product-card__bottom-wrapper {
      padding-top: 2px;
    }
    /deep/ .bff-price-container__top {
      padding: 0 4px 0 8px;
      height: 100%;
      text-align: center;
    }
    /deep/ .bff-price-container {
      margin: 0 auto;
      min-width: 1.52rem;
      width: fit-content;
      max-width: calc(100% - 6px); // 避免100%,截断下降箭头
      min-height: unset;
      height: 18px;
      border-radius: 9px;
      background: linear-gradient(93.84deg, #ff3f3f 7.44%, #ff7144 97.19%);
      justify-content: center;
      position: relative;
      overflow: unset;
      padding: 0;
      .product-card__camel-case-price__placeholder {
        display: none !important; // 隐藏占位符，保持加强价格垂直居中
      }
      .prices-info__sale-price {
        width: 100%;
        color: #fff;
      }
      &::after {
        position: absolute;
        left: -3px;
        top: 45%;
        content: '';
        display: block;
        width: 12px;
        height: 16px;
        background-image: url('https://shein.ltwebstatic.com/svgicons/2024/07/18/17212901792622301071.svg');
        transform: translateY(-50%) scaleX(1)
          /* rtl: translateY(-50%) scaleX(-1) */;
      }
    }
  }
  // 不贵组件，不展示合规价，不加强价格，固定桔红色
  .super-deals__item:not(.super-deals__item_suggested):not(.product-item__discount_color-hide):not(.strengthen-price) /deep/ .prices-info__sale-price {
    color: @sui_color_discount;
  }
  &__text {
    display: flex;
    color: #000;
    font-size: 0.3733rem;
    font-weight: 700;
    line-height: 0.4rem; /* 107.143% */
    letter-spacing: -0.0133rem;
    text-transform: capitalize;
    img {
      width: 0.2933rem;
      height: 0.4267rem;
    }
  }
  &__title {
    max-width: 3.2rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 0.2933rem;
    font-style: normal;
    line-height: normal;
  }
  &::after {
    position: absolute;
    content: '';
    top: 30%;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 72%;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(255, 255, 255) 85%
    );
    border-radius: 0.0533rem;
  }
}
</style>
