// 灵活组件边框配置
export const borderMap = {
  flashSale: '//img.ltwebstatic.com/images3_ccc/2024/07/05/b1/17201649333c4d2e4e440685b2098d70b8377af682.png',
}

// 灵活组件角标配置
export const badgeMap = {
  superDeals: '//img.ltwebstatic.com/images3_ccc/2024/06/14/03/17183712071d6614ef3465ea067c0ae50d311b78d8.png',
  flashSale: '//img.ltwebstatic.com/images3_ccc/2024/06/11/b6/171808863292ea5aa9c32e7460a396b2d65f1099fc.png',
  flashSaleIcon: '//img.ltwebstatic.com/images3_ccc/2024/06/12/46/1718195369e96d6e46b3bb7e2a1b9789f20c786d46.png',
}

// 灵活组件腰带配置
export const beltMap = {
  superDeals: '//img.ltwebstatic.com/images3_ccc/2024/06/18/1f/1718676697bef24d9182095a3106cc71b1dc070177.png',
  superDealsNew: '//img.ltwebstatic.com/images3_ccc/2024/06/11/b6/1718088632cfe7585c120516a943aa993534cc47a0.png',
  superDealsIcon: '//img.ltwebstatic.com/images3_ccc/2024/06/12/46/17181953690958c26c86de5a348a6bd7aeeefe5dee.png',
  flashSale: '//img.ltwebstatic.com/images3_ccc/2024/07/05/b1/17201649336c08a933c77e85e9ffbf69d69f978413.png',
}